<div class="row">
  <div class="p-b-2">
    <div class="col s8 m5 l6 push-l1 push-m1 push-s4">
      <div class="header-title">
        <h6 *ngIf="sectionName">{{sectionName}}</h6>
        <h6 *ngIf="!sectionName">Events</h6>
      </div>
    </div>

    <div class="col s12 m6 l4 pull-l1 right side-tab-content ">
      <!-- <div class="switch" style=" border:2px solid ; padding:  3px 10px;" [ngStyle]="{'color': themeColor} ">
          <span id="toggleText" class="toggle-span-text">{{ switchText }}</span>
          <label>
            <input type="checkbox" id="toggleSwitch" (change)="toggleSwitch($event)">
            <span class="lever"></span>
          </label>
        </div> -->

      <div class="col s12" style="border: 2px solid rgb(200, 200, 200); border-radius: 5px; padding: 0;">
        <!-- Active Events Button -->
        <div [ngStyle]="{'background-color': !isIncludePastEvents ? (isActiveEvents ? themeColor : 'white') : 'white'}"
          class="col l4 s4 active-tab" (click)="activeEvents()">
          <span [ngStyle]="{'color': !isIncludePastEvents ? (isActiveEvents ? 'white' : 'black') : 'black'}"
            class="">Active Events</span>
        </div>
        <!-- Inactive Events Button -->
        <div [ngStyle]="{'background-color': isIncludePastEvents ? (isInactiveEvents ? themeColor : 'white') : 'white'}"
          class="col l4 s4 inactive-tab" (click)="inactiveEvents()">
          <span [ngStyle]="{'color': isIncludePastEvents ? (isInactiveEvents ? 'white' : 'black') : 'black'}"
            class="">Inactive Events</span>
        </div>
        <!-- All Events Button -->
        <div
          [ngStyle]="{'background-color': (!isIncludePastEvents && !isActiveEvents && !isInactiveEvents) ? themeColor : 'white'}"
          class="col l4 s4 all-tab" (click)="allEvents()">
          <span
            [ngStyle]="{'color': (!isIncludePastEvents && !isActiveEvents && !isInactiveEvents) ? 'white' : 'black'}"
            class="">All Events</span>
        </div>




      </div>
    </div>
    <div class="divider col l10 m8 s10 push-l1 push-m2 push-s1 grey lighten-3"></div>
  </div>

  <div class="col l10 push-l1 m10 push-m1 s12 container-events">
    <!-- <div class="col s12 m6 l4 xl4" *ngFor="let event of events">
      <a [routerLink]="['/events-detail', event.id]"> -->
    <div class="col s12 m6 l4 xl4" *ngFor="let event of filteredEvents">
      <a [routerLink]="[isMember ? '/member/event-detail' : '/events-detail', event.id]">
        <div class="example-2 card">
          <!-- =============================== image card -->
          <div class="wrapper" [ngStyle]="{'background-color': themeColor} " *ngIf="event.imgLink">
            <img class="wrapper responsive-img " [src]="event.imgLink">

            <div class="header">
              <div class="date" [ngStyle]="{'color': themeColor} ">
                <span class="day">{{event.eventDate.split('/')[0]}}</span>
                <span class="month">{{event.eventDate.split('/')[1]}}</span>
              </div>
            </div>

            <div class="data">
              <div class="content">
                <span class="author " *ngIf="event.startsAt"><i
                    class="tiny material-symbols-outlined p-r-1">schedule</i>
                  {{event.startsAt}} - {{event.endsAt}} </span>
                <h1 class="title  short-body-snippet"><a href="#"><span [innerHTML]="event.title"></span></a></h1>
                <p class="text"><span class="description-section bold-100 middle-body-snippet"
                    [innerHTML]="event.body"></span></p>
                <a [routerLink]="['/events-detail', event.id]" class="button left"
                  [ngStyle]="{'color': themeColor} ">Read more</a>
              </div>
            </div>
          </div>

          <!-- =============================== no image card -->
          <div class="wrapper" [ngStyle]="{'background-color': themeColor} " *ngIf="!event.imgLink">
            <div class="header">
              <div class="date" [ngStyle]="{'color': themeColor} ">
                <span class="day">{{event.eventDate.split('/')[0]}}</span>
                <span class="month">{{event.eventDate.split('/')[1]}}</span>
              </div>
            </div>

            <div class="data1">
              <div class="content">
                <span class="author " *ngIf="event.startsAt"><i
                    class="tiny material-symbols-outlined p-r-1">schedule</i>
                  {{event.startsAt}} - {{event.endsAt}} </span>
                <h1 class="title short-body-snippet"><a href="#"><span [innerHTML]="event.title"></span></a></h1>
                <p class="text"><span class="description-section bold-100 middle-body-snippet"
                    [innerHTML]="event.body"></span></p>
                <a [routerLink]="['/events-detail', event.id]" class="button left"
                  [ngStyle]="{'color': themeColor} ">Read
                  more</a>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>