<div class="row">
  <div class="col s12 m12 l12">
    <div *ngIf="step1">
      <div class="body-h">
        <div class="header-setting b-m-2">
          <div class="col l12 m12 s12">
            <h4 class="">Create Survey / Poll </h4>
          </div>
        </div>

        <main class="StickyContent  ">
          <form [formGroup]="surveyForm" (ngSubmit)="submitSurveyInfo(surveyForm.value)">

            <div class="card-panel white">
              <div class="row">
                <div class="input-field col s12 l6">
                  <div class="label-input">Survey or Polls?<span class="red-text">*</span></div>
                  <select formControlName="type" class="validate browser-default"
                    (change)="typeChanges($event.target.value)">
                    <option value="" disabled selected>Choose your option</option>
                    <option value="Survey">Survey</option>
                    <option value="Poll">Poll</option>
                  </select>
                </div>

                <div class="input-field col s12 l6">
                  <div class="label-input">Poll/Survey Name</div>
                  <input id="surveyName" type="text" formControlName='surveyName' required class="validate"
                    autocomplete="off" style="text-transform: capitalize;" />
                  <div *ngIf="(surveyName.dirty || surveyName.touched) && surveyName.invalid">
                    <small *ngIf="surveyName.errors.required" class="red-text"> The Poll/Survey Name is required.
                    </small>
                  </div>
                </div>

                <div class="input-field col s12 l6">
                  <div class="label-input">Start Date</div>
                  <input id="startDate" type="text" class="datepicker" (change)="startDateChange()"
                    formControlName='startDate' autocomplete="off" />
                  <div *ngIf="(startDate.dirty || startDate.touched) && startDate.invalid">
                    <small *ngIf="startDate.errors.required" class="red-text"> The Start Date is required. </small>
                  </div>
                </div>

                <div class="input-field col s12 l6">
                  <div class="label-input">End Date</div>
                  <input id="endDate" type="text" class="datepicker" (change)="endDateChange()"
                    formControlName='endDate' autocomplete="off" />
                  <div *ngIf="(endDate.dirty || endDate.touched) && endDate.invalid">
                    <small *ngIf="endDate.errors.required" class="red-text"> The End Date is required. </small>
                  </div>
                </div>

                <div class="row input-field col s12 l6">
                  <div class="row col s12">Do You Want to Show the Results ? <span class="red-text">*</span>
                  </div>
                  <div class="row col s12">
                    <label class="col s6">
                      <input class="with-gap" formControlName="showResult" name="showResult" type="radio" value="yes"
                        (change)="onShowResultYes($event)" />
                      <span>Yes</span>
                    </label>
                    <label class="col s6">
                      <input class="with-gap" formControlName="showResult" name="showResult" type="radio" value="no"
                        (change)="onShowResultNo($event)" />
                      <span>No</span>
                    </label>
                  </div>

                  <div class="input-field col s12" *ngIf="showSurveyResult">
                    <div class="label-input">Show Results To<span class="red-text">*</span></div>
                    <select formControlName="showResultTo" class="validate browser-default">
                      <option value="" disabled selected>Choose your option</option>
                      <option value="member">Member Only</option>
                      <option value="public">General Public</option>
                      <option value="both">Both</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row col s12">
              <div class="section"></div>
              <a class="col l3 s12 btn back-button bold-600" (click)="backClicked()">
                Back To Home Page </a>
              <button type="submit" [disabled]="!surveyForm.valid" style="margin-bottom: 15px;"
                class="col l3 s12 offset-l5 btn save-button" [ngClass]="'btn ' + buttonType">
                Next >
              </button>
            </div>
          </form>
        </main>
      </div>
    </div>

    <div *ngIf="step2">
      <div class="col s12">
        <div class="body-h">

          <div class="header-setting b-m-2">
            <div class="col l9 m9 s12">
              <h4 *ngIf="createPoll">Create Poll</h4>
              <h4 *ngIf="!createPoll">Create Survey</h4>
              <select class="browser-default hide-on-med-and-up">
                <option value="0" disabled selected>About Us</option>
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
              </select>
            </div>

            <div class="col l3  m4 s12 p-m-2 ">
              <button [disabled]="!questionForm.valid" (click)="addQuestion()"
                class="add-button btn white center-align p-l-2" [ngStyle]="{'color': themeColor}"><i
                  class="material-icons-outlined">add</i> New Question</button>
            </div>
          </div>

          <main class="StickyContent white ">
            <form [formGroup]="questionForm" (ngSubmit)="submitQuestions(questionForm.value)">
              <ul class="collapsible col s12 l11 m11">
                <li formArrayName="questionItems"
                  *ngFor="let item of questionForm.get('questionItems').controls; let i = index;">

                  <div class="collapsible-header">
                    <h4 class="col s11 sub-title-section1 bold-100"><span class="bold-600">Question {{i + 1}} :
                      </span>{{item.question}} </h4>

                    <div class="col s1">
                      <a class="btn-floating btn waves-effect waves-light white" *ngIf="i > 0"
                        (click)="removeFile(i)"><i class="material-symbols-outlined bold-700 center red-text">
                          remove</i></a>
                    </div>
                  </div>

                  <div class="collapsible-body">
                    <div [formGroupName]="i">
                      <div class="row">
                        <div class="white">
                          <div class=" row">
                            <div class="input-field col s12 l10">
                              <div class="label-input">Question</div>
                              <input id="" type="text" formControlName="question" required class="validate"
                                autocomplete="off" style="text-transform: capitalize;" placeholder="Question" />
                              <div *ngIf="(item.controls.question.dirty ||
                  item.controls.question.touched) &&
                  item.controls.question.invalid">
                                <small *ngIf="item.controls.question.errors.required" class="red-text"> The Question is
                                  required.
                                </small>
                              </div>
                            </div>

                            <div class="row input-field col s12 l10">
                              <div class="label-input">Answer Type</div>
                              <select formControlName="answerType" required class="validate browser-default"
                                (change)="answerTypeChange($event.target.value, i)">
                                <option value="" disabled selected>Please Select Answer Type</option>
                                <option *ngFor="let answer of answerTypes" [value]="answer.type" title="Tooltip">
                                  {{answer.type}}
                                  <span class="green-text right"> ( {{answer.helpText}} )</span>
                                </option>
                              </select>

                              <div *ngIf="(item.controls.answerType.dirty ||
                  item.controls.answerType.touched) &&
                  item.controls.answerType.invalid">
                                <small *ngIf="item.controls.answerType.errors.required" class="red-text"> The Type is
                                  required.
                                </small>
                              </div>
                            </div>

                            <div *ngIf="item.controls.answerType.value === 'Multiple Choice'">
                              <div class="row input-field col s12 m5 right">
                                <label>
                                  <input type="checkbox" formControlName="allowMultipleAnswers" />
                                  <span>Allow Multiple Answers</span>
                                </label>
                              </div>
                              <br />
                              <div class="row input-field col s12" formArrayName="answers"
                                *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                <div [formGroupName]="j">
                                  <a *ngIf="j > 1" class="btn-flat waves-effect waves-light col s1 right"><i
                                      class="material-icons red-text prefix"
                                      (click)="removeAnswer(i, j)">remove_circle_outline</i></a>
                                  <div class="row input-field col s11">
                                    <div class="label-input">Choice {{j+1}} </div>
                                    <input id="answer" type="text" formControlName="answer" required class="validate"
                                      autocomplete="off" style="text-transform: capitalize;" placeholder="Answer" />
                                    <div
                                      *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                      <small *ngIf="ans.controls.answer.errors.required" class="red-text"> Answer
                                        {{j+1}} is
                                        required.</small>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row col s12">
                                <div class="section"></div>
                                <button type="button" style="margin-bottom: 15px;"
                                  class="col s3 offset-s9 btn add-button white" (click)="onAddAnswer(i)"
                                  [ngStyle]="{'color': themeColor}"
                                  [disabled]="!questionForm.get('questionItems').controls[i].get('answers').valid"
                                  style="cursor: pointer; text-align: center;">
                                  <i class="material-icons">add</i> Add Answer
                                </button>
                              </div>
                            </div>

                            <div *ngIf="item.controls.answerType.value === 'Scale'" formGroupName="scales">
                              <div class="input-field col s6 m3">
                                <i class="material-icons prefix green-text">linear_scale</i>
                                <select formControlName="start" class="validate">
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                </select>
                                <label for="start">Start At</label>
                              </div>

                              <div class="input-field col s6 m3">
                                <i class="material-icons prefix green-text">label</i>
                                <input id="startLabel" type="text" formControlName='startLabel' class="validate" />
                                <label for="startLabel">Label</label>
                              </div>

                              <div class="input-field col s6 m3">
                                <i class="material-icons prefix blue-text">linear_scale</i>
                                <select formControlName="end" class="validate">
                                  <option *ngFor="let s of scales" [value]="s">{{s}}</option>
                                </select>
                                <label for="end">End At</label>
                              </div>

                              <div class="input-field col s6 m3">
                                <i class="material-icons prefix blue-text">label</i>
                                <input id="endLabel" type="text" formControlName='endLabel' class="validate" />
                                <label for="endLabel">Label</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="row col s12">
                <div class="section p-t-3"></div>
                <a class="col l3 s12  btn back-button bold-600" (click)="backClicked()">
                  Back To Previous Page </a>
                <button type="submit" [disabled]="!questionForm.valid" style="margin-bottom: 15px;"
                  class="col l3 s12 offset-l5 btn save-button" [ngClass]="'btn ' + buttonType">
                  <span *ngIf="createPoll"> Save Poll</span>
                  <span *ngIf="!createPoll">Save Survey</span>
                </button>
              </div>
            </form>
          </main>
        </div>
      </div>
    </div>
  </div>
</div>