<div class="row">
  <div class="col s12 ">
    <div class="body-h">
      <div class="header-setting b-m-2 ">
        <div class="col l8 m9 s12">
          <h4 *ngIf="!editName">
            <span *ngIf="sectionName">{{sectionName}}</span>
            <span *ngIf="!sectionName">Survey / Polls List</span>
            <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
          </h4>
          <div class="input-field" *ngIf="editName">
            <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
            <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
              (click)="saveName()">Save</button>
          </div>
          <p class="col s12 bold-100 sub-title-section3">{{description}}</p>
        </div>

        <div class="col l4  m4 s12 p-m-2">
          <a [routerLink]="['/admin/dashboard-sub-menu/polls-surveys']" class="add-button btn white center-align p-l-2"
            [ngStyle]="{'color': themeColor}"><i class="material-icons-outlined">add</i>Add 
            <span *ngIf="sectionName">{{sectionName}}</span>
            <span *ngIf="!sectionName">Survey / Polls List</span></a>
        </div>
      </div>

      <main class="StickyContent">
        <div class="row ">
          <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

          <div class="col s12 l12">
            <div class="row card-panel white col s12" *ngIf="!model && !showLoader">
              <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> 0 Survey /Poll Found In
                  the
                  Database</strong> </p>
            </div>
            <div *ngIf="model">
              <div class="row col s12 l5 card-panel white" *ngFor="let item of model; let i=index;"
                style="margin: 9px;">
                <div class="col s12">
                  <div class="row">
                    <h6 class="truncate bold-700 title-section3">{{item.name}}</h6>
                  </div>
                  <ul class="row collection">
                    <li class="row collection-item">
                      <div class="col s4"> <strong class="black-text">End Date</strong></div>
                      <div class="col s8 red-text"> <strong class="right"> {{item.endDate}} </strong></div>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col s12 m6" style="margin-top: 5px; margin-bottom: 5px;">
                    <a class="center col btn-flat s12 blue lighten-4"
                      [routerLink]="['/admin/dashboard-sub-menu/survey-result', item.id]"> <span
                        style="cursor: pointer;"><i class="material-icons black-text"
                          style="margin-right: 7px;">poll</i>
                        <strong class="black-text">Live Results</strong></span></a>
                  </div>
                  <div class="col s12 m6" style="margin-top: 5px; margin-bottom: 5px;">
                    <a class="center btn-flat col s12 green lighten-4"
                      [routerLink]="['/admin/dashboard-sub-menu/edit-survey', item.id]"><span
                        style="cursor: pointer;"><i class="center material-icons black-text"
                          style="margin-right: 7px;">edit</i><strong class="center black-text">Edit
                          Survey</strong>
                      </span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="section"></div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>