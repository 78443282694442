import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SortablejsModule } from 'ngx-sortablejs';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { TextMaskModule } from 'angular2-text-mask';
import { InputMaskModule } from '@ngneat/input-mask';
import { NgChartsModule } from 'ng2-charts';
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { AngularMyDatePickerModule } from 'angular-mydatepicker';
// import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AdminRoutingModule } from './admin-routing.module';

import { AdminMainComponent } from './admin-main/admin-main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContentMainComponent } from './static-content/content-main/content-main.component';
import { HomePageComponent } from './static-content/home-page/home-page.component';
import { SharedModule } from '../shared/shared.module';

import { RegistredClientsComponent } from './members/registred-clients/registred-clients.component';
import { MembersComponent } from './members/members.component';
import { ApprovePaymentsComponent } from './approve-payments/approve-payments.component';
import { MessengerComponent } from './messenger/messenger.component';
import { SkillsDatabankComponent } from './skills-databank/skills-databank.component';
import { EmployersListComponent } from './Employers/employers-list/employers-list.component';
import { AdminsListComponent } from './manage-admins/admins-list/admins-list.component';
import { ClientDetailComponent } from './members/registred-clients/client-detail/client-detail.component';

import { AdminDetailComponent } from './manage-admins/admin-detail/admin-detail.component';
import { EmployerDetailComponent } from './Employers/employer-detail/employer-detail.component';
import { RegistrationRequestComponent } from './members/registration-request/registration-request.component';
import { RequestDetailComponent } from './members/registration-request/request-detail/request-detail.component';
import { DepartmentComponent } from './department/department.component';
import { AddEditDepartmentComponent } from './department/add-edit-department/add-edit-department.component';
import { AddEditSubdepartmentComponent } from './department/add-edit-subdepartment/add-edit-subdepartment.component';

import { GovernanceComponent } from './governance-main/governance/governance.component';
import { AddGovernanceComponent } from './governance-main/governance/add-governance/add-governance.component';
import { EditGovernanceComponent } from './governance-main/governance/edit-governance/edit-governance.component';
import { AddChiefComponent } from './governance-main/governance/add-chief/add-chief.component';
import { AddCouncillorComponent } from './governance-main/governance/add-councillor/add-councillor.component';

import { ManageMembersComponent } from './members/manage-members/manage-members.component';
import { ArchivedPostComponent } from './static-content/home-page/archived-post/archived-post.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { MemberDetailComponent } from './members/manage-members/member-detail/member-detail.component';

import { StatsMainComponent } from './stats/stats-main/stats-main.component';
import { DemographicsComponent } from './stats/demographics/demographics.component';

import { ManageDepartmentComponent } from './department/manage-department/manage-department.component';
import { ManageSubDepartmentComponent } from './department/manage-sub-department/manage-sub-department.component';
import { ManageDepartmentMembersComponent } from './department/manage-department-members/manage-department-members.component';
import { AddMemberToDepartmentComponent } from './department/manage-department-members/add-member-to-department/add-member-to-department.component';
import { AdminHomeSubMenuComponent } from './admin-home-sub-menu/admin-home-sub-menu.component';
import { EditDepartmentEmployeeComponent } from './department/manage-department-members/edit-department-employee/edit-department-employee.component';
// import { AboutUsComponent } from './about-us/about-us.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { JobPostingsComponent } from './Employers/job-postings/job-postings.component';
import { UpcomingBirthdaysComponent } from './members/upcoming-birthdays/upcoming-birthdays.component';
import { CongratulateMembersComponent } from './members/congratulate-members/congratulate-members.component';
import { MembersTurning16Component } from './members/members-turning16/members-turning16.component';
import { MembersTurning18Component } from './members/members-turning18/members-turning18.component';
import { MembersTurning21Component } from './members/members-turning21/members-turning21.component';
import { SearchMembersComponent } from './members/search-members/search-members.component';
import { PollsAndSurveyComponent } from './polls-and-survey/polls-and-survey.component';
import { SurveyListComponent } from './polls-and-survey/survey-list/survey-list.component';
import { NewPollComponent } from './polls-and-survey/Polls/new-poll/new-poll.component';
import { PollsListComponent } from './polls-and-survey/Polls/polls-list/polls-list.component';
import { PollResultsComponent } from './polls-and-survey/Polls/poll-results/poll-results.component';
import { EditPollComponent } from './polls-and-survey/Polls/edit-poll/edit-poll.component';
import { PostNotificationComponent } from './static-content/home-page/post-notification/post-notification.component';
import { EditNotificationComponent } from './static-content/home-page/edit-notification/edit-notification.component';
import { NewAboutSectionComponent } from './about-us/new-about-section/new-about-section.component';
import { UpdateAboutSectionComponent } from './about-us/update-about-section/update-about-section.component';
import { ListDepNewsComponent } from './department/department-news/list-dep-news/list-dep-news.component';
import { AddDepNewsComponent } from './department/department-news/add-dep-news/add-dep-news.component';
import { EditDepNewsComponent } from './department/department-news/edit-dep-news/edit-dep-news.component';
import { MyInboxComponent } from './messenger/my-inbox/my-inbox.component';
import { EditProfileComponent } from './employee/edit-profile/edit-profile.component';
import { InviteEmployeeComponent } from './department/manage-department-members/invite-employee/invite-employee.component';

import { ClientSessionsComponent } from './members/registred-clients/client-sessions/client-sessions.component';
import { DemoRequestListComponent } from './manage-admins/admin-demo-request/demo-request-list/demo-request-list.component';
import { DemoRequestDetailsComponent } from './manage-admins/admin-demo-request/demo-request-details/demo-request-details.component';
import { DemoVideosComponent } from './demo-videos/demo-videos.component';
import { NewDemoVideoComponent } from './demo-videos/new-demo-video/new-demo-video.component';
import { EditDemoVideoComponent } from './demo-videos/edit-demo-video/edit-demo-video.component';
import { AppSettingsComponent } from './settings/app-settings/app-settings.component';
import { ContactSettingsComponent } from './settings/contact-settings/contact-settings.component';
import { AppStatsComponent } from './stats/app-stats/app-stats.component';
import { DepartmentsStatsComponent } from './stats/departments-stats/departments-stats.component';
import { EventsListComponent } from './static-content/events/events-list/events-list.component';
import { CreateEventComponent } from './static-content/events/create-event/create-event.component';
import { EditEventComponent } from './static-content/events/edit-event/edit-event.component';
import { GovernanceMainComponent } from './governance-main/governance-main.component';
import { ElectionComponent } from './governance-main/election/election.component';
import { NewElectionComponent } from './governance-main/election/new-election/new-election.component';
import { EditElectionComponent } from './governance-main/election/edit-election/edit-election.component';
import { NominationCandidateListComponent } from './governance-main/candidate-section/nomination-candidate-list/nomination-candidate-list.component';
import { ChooseCandidateComponent } from './governance-main/candidate-section/choose-candidate/choose-candidate.component';
import { LandingPageComponent } from './settings/landing-page/landing-page.component';
import { AppMenuComponent } from './settings/app-menu/app-menu.component';
import { AdminActivityComponent } from './manage-admins/admin-activity/admin-activity.component';
import { RegistrationFormComponent } from './settings/registration-form/registration-form.component';


import { PostJobComponent } from './Employers/job-postings/post-job/post-job.component';
import { EditJobComponent } from './Employers/job-postings/edit-job/edit-job.component';
import { ArchiveJobComponent } from './Employers/job-postings/archive-job/archive-job.component';
import { EmployersComponent } from './Employers/employers.component';
import { TrainingsComponent } from './Employers/trainings/trainings.component';
import { AddTrainingComponent } from './Employers/trainings/add-training/add-training.component';
import { EditTrainingComponent } from './Employers/trainings/edit-training/edit-training.component';
import { ArchiveTrainingComponent } from './Employers/trainings/archive-training/archive-training.component';
import { MessagingComponent } from './messaging/messaging.component';
import { AddMembersComponent } from './members/add-members/add-members.component';
import { MembersProfileUpdatesComponent } from './members/members-profile-updates/members-profile-updates.component';
import { IncomingApplicationsComponent } from './incoming-applications/incoming-applications.component';
import { PostSecondayComponent } from './incoming-applications/post-seconday/post-seconday.component';
import { IncomeAssistanceComponent } from './incoming-applications/income-assistance/income-assistance.component';
import { AddBoardComponent } from './governance-main/boards/add-board/add-board.component';
import { BoardsListComponent } from './governance-main/boards/boards-list/boards-list.component';
import { BoardMembersComponent } from './governance-main/boards/board-members/board-members.component';
import { AddBoardChairComponent } from './governance-main/boards/add-board-chair/add-board-chair.component';
import { EditSurveyComponent } from './polls-and-survey/edit-survey/edit-survey.component';
import { SurveyResultsComponent } from './polls-and-survey/survey-results/survey-results.component';
import { ElectionResultComponent } from './governance-main/election/election-result/election-result.component';
import { AreaOfInterestAdminComponent } from './settings/area-of-interest-admin/area-of-interest-admin.component';

import { ActivityDetailComponent } from './manage-admins/admin-activity/activity-detail/activity-detail.component';
import { HousingComponent } from './housing/housing.component';
import { NewPropertyComponent } from './housing/new-property/new-property.component';
import { PropertyDetailComponent } from './housing/property-detail/property-detail.component';
import { UpdatePropertyComponent } from './housing/update-property/update-property.component';
import { RepairRequestsComponent } from './housing/repair-requests/repair-requests.component';
import { RenovationRequestsComponent } from './housing/renovation-requests/renovation-requests.component';
import { ManageOtherRolesComponent } from './manage-other-roles/manage-other-roles.component';
import { ManageCouncillorAccountComponent } from './manage-other-roles/manage-councillor-account/manage-councillor-account.component';
import { ManageBoardMemberAccComponent } from './manage-other-roles/manage-board-member-acc/manage-board-member-acc.component';
import { ManageChiefAccountComponent } from './manage-other-roles/manage-chief-account/manage-chief-account.component';
import { NationSettingsComponent } from './settings/nation-settings/nation-settings.component';
import { ShareNewFileComponent } from './file-sharing/share-new-file/share-new-file.component';
import { FileManagerComponent } from './file-sharing/file-manager/file-manager.component';
import { HousingApplicationComponent } from './incoming-applications/housing-application/housing-application.component';
import { CreateApplicationComponent } from './applications/create-application/create-application.component';


import { ArchivedBoardMembersComponent } from './governance-main/boards/archived-board-members/archived-board-members.component';
import { ApplicationListComponent } from './applications/application-list/application-list.component';
import { PreviewApplicationsComponent } from './applications/preview-applications/preview-applications.component';
import { ApplicationsComponent } from './applications/applications.component';
import { EditApplicationComponent } from './applications/edit-application/edit-application.component';
import { LandingPageSettingsComponent } from './settings/landing-page-settings/landing-page-settings.component';
import { AdminMenuSettingsComponent } from './settings/admin-menu-settings/admin-menu-settings.component';
import { EmployeesRegistrationRequestComponent } from './department/employees-registration-request/employees-registration-request.component';
import { ScisApplicationsComponent } from './incoming-applications/scis-applications/scis-applications.component';
import { LrrcnApplicationComponent } from './applications/lrrcn-application/lrrcn-application.component';

import { NewSchoolComponent } from './school/new-school/new-school.component';
import { SchoolComponent } from './school/school.component';
import { SchoolDetailComponent } from './school/school-detail/school-detail.component';
import { SchoolListComponent } from './school/school-list/school-list.component';
import { ClassRoomComponent } from './school/class-room/class-room.component';
import { AddEditStaffComponent } from './school/staff/add-edit-staff/add-edit-staff.component';
import { StaffComponent } from './school/staff/staff.component';
import { SearchPipe } from 'src/app/Pipes/Search.pipe';
import { SubjectComponent } from './school/subject/subject.component';
import { StaffDetailComponent } from './school/staff/staff-detail/staff-detail.component';
import { LrrcnApplicationListComponent } from './applications/lrrcn-application/lrrcn-application-list/lrrcn-application-list.component';
import { ClassRoomDetailComponent } from './school/class-room/class-room-detail/class-room-detail.component';
import { CoursesComponent } from './courses/courses.component';
import { AddCourseComponent } from './courses/add-course/add-course.component';
import { EditCourseComponent } from './courses/edit-course/edit-course.component';
import { ContactMembersComponent } from './members/contact-members/contact-members.component';
import { MemberDemographicsComponent } from './members/member-demographics/member-demographics.component';
import { MemberProfileComponent } from './members/member-profile/member-profile.component';
import { DisabledMembersComponent } from './members/disabled-members/disabled-members.component';
import { SettingsComponent } from './settings/settings.component';
import { DashboardSettingComponent } from './settings/dashboard-setting/dashboard-setting.component';
import { NewsEventsComponent } from './news-events/news-events.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { LoginAndSignupSettingsComponent } from './settings/login-and-signup-settings/login-and-signup-settings.component';
import { MemberMenuSettingsComponent } from './settings/member-menu-settings/member-menu-settings.component';
import { MemberVerificationComponent } from './members/member-verification/member-verification.component';
import { SectionSettingComponent } from './settings/section-setting/section-setting.component';
import { AddSectionComponent } from './settings/section-setting/add-section/add-section.component';
import { CustomSectionsComponent } from './custom-sections/custom-sections.component';
import { AddCustomSectionComponent } from './custom-sections/add-custom-section/add-custom-section.component';
import { InviteAdminComponent } from './manage-admins/invite-admin/invite-admin.component';
import { SectionListComponent } from './section-list/section-list.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { LandingEditorPageComponent } from './landing-editor-page/landing-editor-page.component';
import { AllMenuSettingsComponent } from './settings/all-menu-settings/all-menu-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProfileComponent } from './profile/profile.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { AboutUsComponent } from './about-us/about-us.component';
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker';
import { NgxMaskDirective , provideNgxMask } from 'ngx-mask';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


@NgModule({ 
    declarations: [
        AdminMainComponent,
        DashboardComponent,
        ContentMainComponent,
        HomePageComponent,
        RegistredClientsComponent,
        MembersComponent,
        ApprovePaymentsComponent,
        MessengerComponent,
        SkillsDatabankComponent,
        EmployersListComponent,
        AdminsListComponent,
        ClientDetailComponent,
        AdminDetailComponent,
        EmployerDetailComponent,
        RegistrationRequestComponent,
        RequestDetailComponent,
        DepartmentComponent,
        AddEditDepartmentComponent,
        AddEditSubdepartmentComponent,
        GovernanceComponent,
        AddGovernanceComponent,
        EditGovernanceComponent,
        AddChiefComponent,
        AddCouncillorComponent,
        ManageMembersComponent,
        ArchivedPostComponent,
        AdminHomeComponent,
        MemberDetailComponent,
        StatsMainComponent,
        DemographicsComponent,
        ManageDepartmentComponent,
        ManageSubDepartmentComponent,
        ManageDepartmentMembersComponent,
        AddMemberToDepartmentComponent,
        AdminHomeSubMenuComponent,
        EditDepartmentEmployeeComponent,
        AboutUsComponent,
        SocialMediaComponent,
        JobPostingsComponent,
        UpcomingBirthdaysComponent,
        CongratulateMembersComponent,
        MembersTurning16Component,
        MembersTurning18Component,
        MembersTurning21Component,
        SearchMembersComponent,
        PollsAndSurveyComponent,
        SurveyListComponent,
        NewPollComponent,
        PollsListComponent,
        PollResultsComponent,
        EditPollComponent,
        PostNotificationComponent,
        EditNotificationComponent,
        NewAboutSectionComponent,
        UpdateAboutSectionComponent,
        ListDepNewsComponent,
        AddDepNewsComponent,
        EditDepNewsComponent,
        MyInboxComponent,
        EditProfileComponent,
        InviteEmployeeComponent,
        ClientSessionsComponent,
        DemoRequestListComponent,
        DemoRequestDetailsComponent,
        DemoVideosComponent,
        NewDemoVideoComponent,
        EditDemoVideoComponent,
        AppSettingsComponent,
        ContactSettingsComponent,
        AppStatsComponent,
        DepartmentsStatsComponent,
        EventsListComponent,
        CreateEventComponent,
        EditEventComponent,
        GovernanceMainComponent,
        ElectionComponent,
        NewElectionComponent,
        EditElectionComponent,
        NominationCandidateListComponent,
        ChooseCandidateComponent,
        LandingPageComponent,
        AppMenuComponent,
        AdminActivityComponent,
        RegistrationFormComponent,
        PostJobComponent,
        EditJobComponent,
        ArchiveJobComponent,
        EmployersComponent,
        TrainingsComponent,
        AddTrainingComponent,
        EditTrainingComponent,
        ArchiveTrainingComponent,
        MessagingComponent,
        AddMembersComponent,
        MembersProfileUpdatesComponent,
        IncomingApplicationsComponent,
        PostSecondayComponent,
        IncomeAssistanceComponent,
        AddBoardComponent,
        BoardsListComponent,
        BoardMembersComponent,
        AddBoardChairComponent,
        EditSurveyComponent,
        SurveyResultsComponent,
        ElectionResultComponent,
        AreaOfInterestAdminComponent,
        ActivityDetailComponent,
        HousingComponent,
        NewPropertyComponent,
        PropertyDetailComponent,
        UpdatePropertyComponent,
        RepairRequestsComponent,
        RenovationRequestsComponent,
        ManageOtherRolesComponent,
        ManageCouncillorAccountComponent,
        ManageBoardMemberAccComponent,
        ManageChiefAccountComponent,
        NationSettingsComponent,
        ShareNewFileComponent,
        FileManagerComponent,
        ArchivedBoardMembersComponent,
        HousingApplicationComponent,
        CreateApplicationComponent,
        ApplicationListComponent,
        PreviewApplicationsComponent,
        ApplicationsComponent,
        EditApplicationComponent,
        LandingPageSettingsComponent,
        AdminMenuSettingsComponent,
        EmployeesRegistrationRequestComponent,
        ScisApplicationsComponent,
        LrrcnApplicationComponent,
        LrrcnApplicationListComponent,
        NewSchoolComponent,
        SchoolComponent,
        SchoolDetailComponent,
        SchoolListComponent,
        ClassRoomComponent,
        StaffComponent,
        StaffDetailComponent,
        AddEditStaffComponent,
        SearchPipe,
        SubjectComponent,
        ClassRoomDetailComponent,
        CoursesComponent,
        AddCourseComponent,
        EditCourseComponent,
        ContactMembersComponent,
        MemberDemographicsComponent,
        MemberProfileComponent,
        DisabledMembersComponent,
        SettingsComponent,
        DashboardSettingComponent,
        NewsEventsComponent,
        SideMenuComponent,
        LoginAndSignupSettingsComponent,
        MemberMenuSettingsComponent,
        MemberVerificationComponent,
        SectionSettingComponent,
        AddSectionComponent,
        CustomSectionsComponent,
        AddCustomSectionComponent,
        InviteAdminComponent,
        SectionListComponent,
        LandingEditorPageComponent,
        AllMenuSettingsComponent,
        ProfileComponent,
    ], imports: [BrowserModule,
        CommonModule,
        AdminRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // SortablejsModule,
        CKEditorModule,
        SharedModule,
        DataTablesModule,
        // TextMaskModule,
        InputMaskModule.forRoot(),
        BsDatepickerModule.forRoot(),
        // DatepickerModule.forRoot(),
        AngularMyDatePickerModule,
        NgChartsModule,
        NgxPaginationModule,
        NgxSliderModule,
        ImageCropperModule,
        HammerModule,
        NgxMaskDirective], providers: [
        DatePipe,
        DragDropModule,
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AdminModule { }
