import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { SearchMemberModel } from 'src/app/models/searchMemberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { toast } from 'materialize-css';
import { MediaService } from 'src/app/services/api/media.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { OtherRolesService } from 'src/app/services/firebase/other-roles.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import DataTables from 'datatables.net';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;
declare var navigator: any;

@Component({
  selector: 'app-add-chief',
  templateUrl: './add-chief.component.html',
  styleUrls: ['./add-chief.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddChiefComponent implements OnInit, OnDestroy {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor = environment.appTheme.adminThemeColor;
  buttonType: any;
  chiefTitle: any;
  
  @ViewChild(DataTableDirective, { static: true }) datatableElement: DataTableDirective;
  nationOfUse = environment.firstNation.displayName;
  // dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  showSearchTable = false;
  searchFormValid = true;
  showChiefForm = false;
  showSearch = true;
  showAddButton: boolean;
  showNextButton = false;
  processing = false;
  modalReference: any;
  employeename: any;
  toggleTab = false;
  addChiefForm: UntypedFormGroup;

  searchMemberForm: UntypedFormGroup;
  memberModel: SearchMemberModel;
  postObj: any;
  employeesListData: any;
  loadingTxt = '';
  fromWhere: any;
  today = new Date();
  appointmentDate: any;
  expiryDate: any;
  selectedFile: File = null;
  profile_pic: any;
  employeeId: any;
  model: any;
  governance_id: any;
  edit_governance: any;
  selected_member: any;

  editing_chief: any;
  chiefBio: any;

  chiefFacebookAcc: any;
  chiefInstagramAcc: any;
  chiefTwitterAcc: any;
  chiefLinkedinAcc: any;
  chiefYoutubeAcc: any;

  base64Image: any;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  // tslint:disable-next-line: max-line-length
  //public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  public urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  departmentsList: any;
  departmentsOptions: any[];
  selectedDepartmentsList: any[];
  selectedMemberFirstName: '';
  selectedMemberLastName: '';
  myimgLink: any;
  binDoc: File;
  imgErrorMsg: any;
  showImageInput = true;
  // showImageFromServer = false;
  oldUrl: any;
  isMobile = false;

  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  showFacebookAcc = 'Private';
  showTwitterAcc = 'Private';
  showInstagramAcc = 'Private';
  showLinkedinAcc = 'Private';
  showYoutubeAcc = 'Private';

  memberID: any;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  deleteItem: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private memberService: MemberService,
    private governanceService: GovernanceService,
    private mediaService: MediaService,
    private departmentService: DepartmentService,
    private fireMember: FireMembersService,
    private emailService: EmailService,
    private otherRolesService: OtherRolesService,
    public appSettingsService: AppSettingsService,
  ) {

    if (typeof window["cordova"] !== 'undefined') {
      this.isMobile = true;
    }

    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';

    this.chiefTitle = this.appSettingsService.getGovTitle().chiefTitle ? this.appSettingsService.getGovTitle().chiefTitle: 'Chief' ;
    
    this.searchMemberForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  ngOnInit() {
    this.governance_id = window.history.state.governance_id;
    this.getGovernanceFormData();
    const path = this.router.url.split('/');
    if (path[path.length - 1] === 'add-chief') {
      this.fromWhere = 'add';
      this.appointmentDate = null;
      this.expiryDate = null;
    } else if (path[path.length - 1] === 'edit-chief') {
      this.fromWhere = 'edit';
      this.getChiefData();
    } else {
      this.router.navigate(['/admin/governance']);
    }
  }

  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }

  // facebook
  privateFacebookClick() {
    this.showFacebookAcc = 'Private';
  }

  publicFacebookClick() {
    this.showFacebookAcc = 'Public';
  }

  membersFacebookClick() {
    this.showFacebookAcc = 'Members';
  }
  // instagram
  privateInstagramClick() {
    this.showInstagramAcc = 'Private';
  }

  publicInstagramClick() {
    this.showInstagramAcc = 'Public';
  }

  membersInstagramClick() {
    this.showInstagramAcc = 'Members';
  }
  // twitter
  privateTwitterClick() {
    this.showTwitterAcc = 'Private';
  }

  publicTwitterClick() {
    this.showTwitterAcc = 'Public';
  }

  membersTwitterClick() {
    this.showTwitterAcc = 'Members';
  }
  // linkedin
  privateLinkedinClick() {
    this.showLinkedinAcc = 'Private';
  }

  publicLinkedinClick() {
    this.showLinkedinAcc = 'Public';
  }

  membersLinkedinClick() {
    this.showLinkedinAcc = 'Members';
  }
  // youtube
  privateYoutubeClick() {
    this.showYoutubeAcc = 'Private';
  }

  publicYoutubeClick() {
    this.showYoutubeAcc = 'Public';
  }

  membersYoutubeClick() {
    this.showYoutubeAcc = 'Members';
  }
  //

  onAppointmentDateChange(event: IMyDateModel): void {
    if (this.addChiefForm) {
      this.addChiefForm.patchValue({ appointmentDate: event.singleDate.formatted });
      this.appointmentDate = event.singleDate.formatted;
    }
  }

  onExpiryDateChange(event: IMyDateModel): void {
    if (this.addChiefForm) {
      this.addChiefForm.patchValue({ expiryDate: event.singleDate.formatted });
      this.expiryDate = event.singleDate.formatted;
    }
  }

  /* On destroy event */
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  //Cancel the search text
  onClearSearchEmployees() {
    this.showSearchTable = false;
    this.showNextButton = false;
    this.showAddButton = true;
    this.searchMemberForm.setValue({
      firstName: '',
      lastName: ''
    });
  }

  onSearchEmployees() {
    this.processing = false;
    if (this.searchMemberForm.value.firstName !== '' || this.searchMemberForm.value.lastName !== '') {
      this.memberModel = new SearchMemberModel();
      this.memberModel.firstName = this.searchMemberForm.value.firstName;
      this.memberModel.lastName = this.searchMemberForm.value.lastName;

      this.memberService.searchMember(this.memberModel).subscribe(x => {
        if (x) {
          this.employeesListData = this.filterMembers(x);

          this.showSearchTable = true;
          this.processing = true;
          // Calling the DT trigger to manually render the table
          this.dtTrigger.next(null);
        } else { this.loadingTxt = 'You dont have any content on your homepage'; }
      });
      this.postObj = { ...this.searchMemberForm.value };
    }
  }

  filterMembers(searched_members) {
    const registred_members = [];
    if (typeof (this.edit_governance.chiefs) !== 'undefined' && this.edit_governance.chiefs.length > 0) {
      Object.keys(this.edit_governance.chiefs).map(x => {
        registred_members.push(this.edit_governance.chiefs[x].applicantID);
      });
    }
    if (typeof (this.edit_governance.councillors) !== 'undefined' && this.edit_governance.councillors.length > 0) {
      Object.keys(this.edit_governance.councillors).map(x => {
        registred_members.push(this.edit_governance.councillors[x].applicantID);
      });
    }
    const not_registered = searched_members.filter((item) => {
      return !registred_members.includes(item.applicantID);
    });
    return not_registered;
  }

  get getFirstName() { return this.addChiefForm.get('firstName'); }
  get getLastName() { return this.addChiefForm.get('lastName'); }
  get getAppointmentDate() { return this.addChiefForm.get('appointmentDate'); }
  get getExpiryDate() { return this.addChiefForm.get('expiryDate'); }
  get getChiefBio() { return this.addChiefForm.get('chiefBio'); }
  get getEmail() { return this.addChiefForm.get('email'); }
  get getCell_phone() { return this.addChiefForm.get('cell_phone'); }
  get getOffice_phone() { return this.addChiefForm.get('office_phone'); }
  get getChiefFacebookAcc() { return this.addChiefForm.get('chiefFacebookAcc'); }
  get getChiefInstagramAcc() { return this.addChiefForm.get('chiefInstagramAcc'); }
  get getChiefTwitterAcc() { return this.addChiefForm.get('chiefTwitterAcc'); }
  get getChiefLinkedinAcc() { return this.addChiefForm.get('chiefLinkedinAcc'); }
  get getChiefYoutubeAcc() { return this.addChiefForm.get('chiefYoutubeAcc'); }
  get website() { return this.addChiefForm.get('website'); }

  /**
   * Set form data
   * @param employeeId
   */
  setFormDetails(selected_member) {
    const found = this.employeesListData.filter((item) => {
      return item.applicantID === selected_member.applicantID;
    });
    this.selected_member = selected_member;
    if (found !== undefined || found !== null) {

      this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
        if (x) {
          this.departmentsList = x;
          this.departmentsOptions = [];
          this.departmentsList.forEach(dep => {
            const option = { id: dep.id, name: dep.name, isSelected: false };
            this.departmentsOptions.push(option);
          });
        }
      });

      this.memberID = this.selected_member.applicantID;
      this.selectedMemberFirstName = this.selected_member.GivenName;
      this.selectedMemberLastName = this.selected_member.LastName;

      this.addChiefForm = this.formBuilder.group({
        firstName: [{ value: this.selectedMemberFirstName, disabled: true }, [Validators.required]],
        lastName: [{ value: this.selectedMemberLastName, disabled: true }, [Validators.required]],
        displayName: [this.selectedMemberFirstName + ' ' + this.selectedMemberLastName],
        chiefBio: [''],
        appointmentDate: [''],
        expiryDate: [''],
        email: ['', [Validators.email, Validators.required]],
        cell_phone: [''],
        website: ['', [Validators.pattern(this.urlPattern)]],
        office_phone: [''],
        imgLink: [''],
        chiefFacebookAcc: ['', [Validators.pattern(this.urlPattern)]],
        chiefInstagramAcc: ['', [Validators.pattern(this.urlPattern)]],
        chiefTwitterAcc: ['', [Validators.pattern(this.urlPattern)]],
        chiefLinkedinAcc: ['', [Validators.pattern(this.urlPattern)]],
        chiefYoutubeAcc: ['', [Validators.pattern(this.urlPattern)]]
      });

      this.showNextButton = true;
      this.showAddButton = false;
      this.showSetForm();

      setTimeout(() => {
        $('.modal').modal();
        $('#detailModal').modal('open');
        M.updateTextFields();
      }, 25);
    }
  }

  selectClick() {
    $('#detailModal').modal('close');
    this.showNextButton = true;
    this.showAddButton = false;
    this.showSetForm();

    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 50);
  }
  /**
   * Show set form when next is clicked
   */
  showSetForm() {
    this.showChiefForm = true;
    this.showSearchTable = false;
    this.showNextButton = false;
    this.showSearch = false;
    this.showAddButton = false;
  }

  /**
   * Click on cancel button of Chief form
   */
  cancelChiefForm() {
    this.router.navigate(['/admin/dashboard-sub-menu/governance']);
  }

  addChiefData() {
    if (this.addChiefForm.valid) {
      let formData = this.addChiefForm.value;

      let chiefData;
      // get user if exists
      this.fireMember.getMemberByAppliciantId(this.memberID).valueChanges().subscribe(chief => {
        if (chief.length > 0) {
          chiefData = chief[0];
          const model: any = {
            uid: chiefData.uid,
            isChief: true,
            isCouncillor: false
          };
          // update account
          this.fireMember.updateAccountRole(model);
          // then notify
          if (this.fromWhere === 'add') {
            const mail = new EmailModel();
            mail.emailList = [formData.email];
            mail.subject = `Added as a councilor`;
            mail.body = `Hello ${formData.displayName},<br/>`;
            mail.body = mail.body += `You have been elected to be a chief by an admin so you can
            customize your profile by logging to your account.`;
            mail.body = mail.body += `<br/>Thank you.`;
            // this.emailService.sendEmail(mail).subscribe(() => {
            //    toast({ html: 'Email Successfully Sent.', classes: 'green' });
            // });
          }
        } else {
          if (this.fromWhere === 'add') {
            // new user so register
            const invitationDetailObj: any = {
              applicantID: this.memberID,
              firstName: this.selectedMemberFirstName,
              lastName: this.selectedMemberLastName,
              displayName: formData['displayName'],
              email: formData['email'],
              isChief: true,
              isCouncillor: false,
              invitationDate: Date.now(),
            };
            this.otherRolesService.saveInvitation(invitationDetailObj);

            // then notify
            let registrationDomain = window.location.hostname;
            if (window.location.hostname === 'localhost') {
              registrationDomain = 'http://localhost:4200';
            } else {

              registrationDomain = `https://${registrationDomain}`;
            }
            registrationDomain = `${registrationDomain}/#/auth/register-other-roles/${this.memberID}`;
            const email = {
              subject: 'Registration Email',
              body: `<p> Good Day <strong style="color: green;"> ` + formData['displayName'] + ` </strong></p>
            <p> Welcome to the "` + this.nationOfUse + `" mobile platform. </p>
        <p> This email is to confirm your registration as an official of our platform. </p>
        <p> Click the link below to complete your registration by creating your password and choosing your security settings.  </p>
       
        </p>

        <br/> <br/>
        <a href="` + registrationDomain + `" target="_blank"> Please Click Here To Complete Registration </a>
        <br/> <p>Thank You & Welcome to "` + this.nationOfUse + `"</p>" `,
              emailList: [],
            };
            email.emailList.push(formData['email']);

            this.emailService.sendEmail(email).subscribe(
              e => {
                if (e) {
                  toast({ html: 'Registration Email Successfully Sent', classes: 'green' });
                }
              }
            );

          }
        }
      });

      let portfolioItems = [];
      this.departmentsOptions.forEach(element => {
        if (element.isSelected) {
          portfolioItems.push({ id: element.id, name: element.name });
        }
      });

      const postObj: any = {
        id: this.memberID,
        firstName: this.selectedMemberFirstName,
        lastName: this.selectedMemberLastName,
        displayName: formData.displayName,
        appointmentDate: (this.appointmentDate) ? this.appointmentDate : '',
        expiryDate: (this.expiryDate) ? this.expiryDate : '',
        chiefBio: formData.chiefBio,
        chiefFacebookAcc: formData.chiefFacebookAcc,
        chiefInstagramAcc: formData.chiefInstagramAcc,
        chiefTwitterAcc: formData.chiefTwitterAcc,
        chiefLinkedinAcc: formData.chiefLinkedinAcc,
        chiefYoutubeAcc: formData.chiefYoutubeAcc,
        email: formData.email,
        cell_phone: formData.cell_phone,
        website: formData.website,
        office_phone: formData.office_phone,
        archive: 0,
        portfolio: portfolioItems,
        profile_pic: this.profile_pic ? this.profile_pic: '',
        showEmail: this.showEmailAdr,
        showMobile: this.showMobileNo,
        showFacebookAcc: this.showFacebookAcc,
        showTwitterAcc: this.showTwitterAcc,
        showInstagramAcc: this.showInstagramAcc,
        showLinkedinAcc: this.showLinkedinAcc,
        showYoutubeAcc: this.showYoutubeAcc,
        showOfficePhone: this.showOfficeNo,
        allowContact: this.allowContact
      };

      if (this.binDoc && this.oldUrl) {
        this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(
          upload => {
            if (upload) {
              postObj.profile_pic = upload.imgLink;
              this.processForm(postObj);
              return;
            }
          }
        );
      } else if (this.binDoc && !this.oldUrl) {
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              postObj.profile_pic = upload.imgLink;
              this.processForm(postObj);
              return;
            }
          }
        );
      } else if (!this.binDoc && this.oldUrl) {
        this.mediaService.DeleteBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              postObj.profile_pic = '';
              this.processForm(postObj);
              return;
            }
          });
      } else if (!this.binDoc && !this.oldUrl) {
        this.processForm(postObj);
        return;
      }


    } else {
      (Object as any).values(this.addChiefForm.controls).forEach((control: any) => {
        control.markAsTouched();
        // this.router.navigate(['/admin/edit-governance'], { state: { governance_id: this.governance_id } });
      });
      return;
    }

  }

  processForm(postObj) {
    //this.edit_governance.id = this.governance_id,
    this.edit_governance.chiefs = [postObj];

    this.governanceService.UpdateGovernanceChief(this.edit_governance).then(x => {

      if (this.fromWhere === 'add') {
        this.fireMember.setMemberAsChief(postObj.id);
        toast({ html: 'Chief Successfully Added!', classes: 'green' });
        this.router.navigate(['/admin/edit-governance'], { state: { governance_id: this.governance_id } });
      }
      if (this.fromWhere === 'edit') {
        toast({ html: 'Chief Profile Successfully Updated!', classes: 'green' });
        return;
      }
    });
  }

  get imgLink() { return this.addChiefForm.get('imgLink'); }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }

    this.binDoc = files[0];
    this.imgErrorMsg = undefined;

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.profile_pic = reader.result;
    };
  }

  deletePhoto() {
    this.deleteItem = 'Photo';
    $('.modal').modal();
  }

  deleteChief() {
    this.deleteItem = 'Chief';
    $('.modal').modal();
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = (this.myimgLink) ? this.myimgLink : undefined;
    this.myimgLink = '';
    this.profile_pic = undefined;
    this.showImageInput = true;
  }

  getChiefData() {
    this.governanceService.getGovernanceList().valueChanges().subscribe(x => {
      if (x) {
        this.edit_governance = x[0];

        const found = this.edit_governance.chiefs[0];
        this.editing_chief = found;
        if (found) {
          this.memberID = this.edit_governance.chiefs[0].id;

          this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
            if (x) {
              this.departmentsList = x;
              this.departmentsOptions = [];
              this.departmentsList.forEach(dep => {
                dep.isSelected = false;
                if (found.portfolio) {
                  found.portfolio.forEach(element => {
                    if (element.id === dep.id) {
                      dep.isSelected = true;
                    }
                  });
                }
                const option = { id: dep.id, name: dep.name, isSelected: dep.isSelected };
                this.departmentsOptions.push(option);
              });
            }
          });

          this.selectedMemberFirstName = found.firstName;
          this.selectedMemberLastName = found.lastName;
          this.addChiefForm = this.formBuilder.group({
            firstName: [{ value: found.firstName, disabled: true }, [Validators.required]],
            lastName: [{ value: found.lastName, disabled: true }, [Validators.required]],
            displayName: [found.displayName],
            chiefBio: [found.chiefBio],
            appointmentDate: [found.appointmentDate],

            expiryDate: [found.expiryDate],
            email: [found.email, [Validators.email, Validators.required]],
            cell_phone: [found.cell_phone],
            website: [found.website, [Validators.pattern(this.urlPattern)]],
            office_phone: [found.office_phone],
            // imgLink: [found.profile_pic],
            chiefFacebookAcc: [found.chiefFacebookAcc, [Validators.pattern(this.urlPattern)]],
            chiefInstagramAcc: [found.chiefInstagramAcc, [Validators.pattern(this.urlPattern)]],
            chiefTwitterAcc: [found.chiefTwitterAcc, [Validators.pattern(this.urlPattern)]],
            chiefLinkedinAcc: [found.chiefLinkedinAcc, [Validators.pattern(this.urlPattern)]],
            chiefYoutubeAcc: [found.chiefYoutubeAcc, [Validators.pattern(this.urlPattern)]],
          });

          this.showEmailAdr = found.showEmail;
          this.showMobileNo = found.showMobile;
          this.showFacebookAcc = found.showFacebookAcc || this.showFacebookAcc; // if not saved use default
          this.showTwitterAcc = found.showTwitterAcc || this.showTwitterAcc;
          this.showInstagramAcc = found.showInstagramAcc || this.showInstagramAcc;
          this.showLinkedinAcc = found.showLinkedinAcc || this.showLinkedinAcc;
          this.showYoutubeAcc = found.showYoutubeAcc || this.showYoutubeAcc;
          this.showOfficeNo = found.showOfficePhone;
          this.allowContact = found.allowContact;

          if (found.profile_pic) {
            this.myimgLink = found.profile_pic;
            this.profile_pic = found.profile_pic;
            this.showImageInput = false;
          }

          this.showSearch = false;
          this.showAddButton = false;
          this.showChiefForm = true;
          // this.profile_pic = found.profile_pic;
          this.appointmentDate = found.appointmentDate;
          this.expiryDate = found.expiryDate;
          this.chiefBio = found.chiefBio;

          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct',
            'Nov', 'Dec'];

          setTimeout(() => {
            if (found.expiryDate) {
              const dateArray1 = found.expiryDate.split('/');
              const year1 = +dateArray1[2];
              const day1 = +dateArray1[0];

              const date1 = new Date(year1, months.indexOf(dateArray1[1]), day1);
              const model1: IMyDateModel = { isRange: false, singleDate: { jsDate: date1 }, dateRange: null };
              this.addChiefForm.patchValue({ expiryDate: model1 });
            }

            if (found.appointmentDate) {
              const dateArray = found.appointmentDate.split('/');
              const year = +dateArray[2];
              const day = +dateArray[0];

              const date2 = new Date(year, months.indexOf(dateArray[1]), day);
              const model2: IMyDateModel = { isRange: false, singleDate: { jsDate: date2 }, dateRange: null };
              this.addChiefForm.patchValue({ appointmentDate: model2 });
            }

            M.updateTextFields();
            M.textareaAutoResize($('#chiefBio'));
            $('.tooltipped').tooltip();
          }, 25);

        }
      }
    });
  }

  getGovernanceFormData() {
    this.governanceService.getGovernanceList().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.edit_governance = x[0];
        }
      }
    });
  }

  archiveChief() {
    const governance = {
      id: this.governance_id,
      chiefs: [],
    };
    this.governanceService.UpdateGovernanceChief(governance).then(x => {
      this.fireMember.removeMemberFromGovernance(this.memberID);
      toast({ html: 'Chief Successfully Deleted!', classes: 'red' });
      this.router.navigate(['/admin/edit-governance'], { state: { governance_id: this.governance_id } });
    });
  }

  mobilePicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'name.png';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.base64Image = 'data:image/jpeg;base64,' + imageData;
          this.profile_pic = 'data:image/jpeg;base64,' + imageData;
          this.imgErrorMsg = undefined;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.CAMERA,
      });
  }

  libraryPicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'name.png';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.base64Image = 'data:image/jpeg;base64,' + imageData;
          this.profile_pic = 'data:image/jpeg;base64,' + imageData;
          this.imgErrorMsg = undefined;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      });
  }

  onMobileCameraFail(message) {
    alert(message);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  public getInitials(firstName: any, lastName: any) {
    if (firstName && lastName) {
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    } else { return ''; }
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') { return true; }
    else if (ext.toLowerCase() === 'jpg') { return true; }
    else if (ext.toLowerCase() === 'jpeg') { return true; }
    else if (ext.toLowerCase() === 'svg') { return true; }
    else if (ext.toLowerCase() === 'webp') { return true; }
    else { return false; }
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

}
