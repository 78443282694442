import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminAuthGuard } from 'src/app/guards/admin-auth.guard';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminHomeSubMenuComponent } from './admin-home-sub-menu/admin-home-sub-menu.component';
import { SideMenuComponent } from './side-menu/side-menu.component';

import { AboutUsComponent } from './about-us/about-us.component';
import { NewAboutSectionComponent } from './about-us/new-about-section/new-about-section.component';
import { UpdateAboutSectionComponent } from './about-us/update-about-section/update-about-section.component';

import { NationSettingsComponent } from './settings/nation-settings/nation-settings.component';

import { DepartmentComponent } from './department/department.component';
import { ManageDepartmentComponent } from './department/manage-department/manage-department.component';
import { AddEditDepartmentComponent } from './department/add-edit-department/add-edit-department.component';
import { AddMemberToDepartmentComponent } from './department/manage-department-members/add-member-to-department/add-member-to-department.component';
import { EditDepartmentEmployeeComponent } from './department/manage-department-members/edit-department-employee/edit-department-employee.component';
import { ListDepNewsComponent } from './department/department-news/list-dep-news/list-dep-news.component';
import { AddDepNewsComponent } from './department/department-news/add-dep-news/add-dep-news.component';
import { EditDepNewsComponent } from './department/department-news/edit-dep-news/edit-dep-news.component';
import { ManageSubDepartmentComponent } from './department/manage-sub-department/manage-sub-department.component';
import { AddEditSubdepartmentComponent } from './department/add-edit-subdepartment/add-edit-subdepartment.component';
import { ManageDepartmentMembersComponent } from './department/manage-department-members/manage-department-members.component';

import { SchoolComponent } from './school/school.component';
import { NewSchoolComponent } from './school/new-school/new-school.component';
import { SchoolDetailComponent } from './school/school-detail/school-detail.component';
import { SchoolListComponent } from './school/school-list/school-list.component';
import { SubjectComponent } from './school/subject/subject.component';
import { ClassRoomComponent } from './school/class-room/class-room.component';
import { ClassRoomDetailComponent } from './school/class-room/class-room-detail/class-room-detail.component';
import { StaffComponent } from './school/staff/staff.component';
import { StaffDetailComponent } from './school/staff/staff-detail/staff-detail.component';
import { AddEditStaffComponent } from './school/staff/add-edit-staff/add-edit-staff.component';
import { CoursesComponent } from './courses/courses.component';
import { AddCourseComponent } from './courses/add-course/add-course.component';
import { EditCourseComponent } from './courses/edit-course/edit-course.component';
import { ContactMembersComponent } from './members/contact-members/contact-members.component';

import { GovernanceComponent } from './governance-main/governance/governance.component';
import { EditGovernanceComponent } from './governance-main/governance/edit-governance/edit-governance.component';
import { AddChiefComponent } from './governance-main/governance/add-chief/add-chief.component';
import { AddCouncillorComponent } from './governance-main/governance/add-councillor/add-councillor.component';
import { ManageCouncillorAccountComponent } from './manage-other-roles/manage-councillor-account/manage-councillor-account.component';
import { ManageChiefAccountComponent } from './manage-other-roles/manage-chief-account/manage-chief-account.component';
import { BoardsListComponent } from './governance-main/boards/boards-list/boards-list.component';
import { AddBoardComponent } from './governance-main/boards/add-board/add-board.component';
import { BoardMembersComponent } from './governance-main/boards/board-members/board-members.component';
import { AddBoardChairComponent } from './governance-main/boards/add-board-chair/add-board-chair.component';
import { ArchivedBoardMembersComponent } from './governance-main/boards/archived-board-members/archived-board-members.component';
import { ManageBoardMemberAccComponent } from './manage-other-roles/manage-board-member-acc/manage-board-member-acc.component';

import { GovernanceMainComponent } from './governance-main/governance-main.component';
import { AddGovernanceComponent } from './governance-main/governance/add-governance/add-governance.component';
import { ElectionComponent } from './governance-main/election/election.component';
import { NewElectionComponent } from './governance-main/election/new-election/new-election.component';
import { EditElectionComponent } from './governance-main/election/edit-election/edit-election.component';
import { ElectionResultComponent } from './governance-main/election/election-result/election-result.component';
import { NominationCandidateListComponent } from './governance-main/candidate-section/nomination-candidate-list/nomination-candidate-list.component';
import { ChooseCandidateComponent } from './governance-main/candidate-section/choose-candidate/choose-candidate.component';

import { AdminsListComponent } from './manage-admins/admins-list/admins-list.component';
import { AdminDetailComponent } from './manage-admins/admin-detail/admin-detail.component';
import { AdminActivityComponent } from './manage-admins/admin-activity/admin-activity.component';
import { ActivityDetailComponent } from './manage-admins/admin-activity/activity-detail/activity-detail.component';
import { InviteAdminComponent } from './manage-admins/invite-admin/invite-admin.component';
import { ManageOtherRolesComponent } from './manage-other-roles/manage-other-roles.component';

import { MembersComponent } from './members/members.component';
import { ManageMembersComponent } from './members/manage-members/manage-members.component';
import { MemberDetailComponent } from './members/manage-members/member-detail/member-detail.component';
import { RegistrationRequestComponent } from './members/registration-request/registration-request.component';
import { RequestDetailComponent } from './members/registration-request/request-detail/request-detail.component';
import { RegistredClientsComponent } from './members/registred-clients/registred-clients.component';
import { ClientDetailComponent } from './members/registred-clients/client-detail/client-detail.component';
import { UpcomingBirthdaysComponent } from './members/upcoming-birthdays/upcoming-birthdays.component';
import { CongratulateMembersComponent } from './members/congratulate-members/congratulate-members.component';
import { MembersTurning16Component } from './members/members-turning16/members-turning16.component';
import { MembersTurning18Component } from './members/members-turning18/members-turning18.component';
import { MembersTurning21Component } from './members/members-turning21/members-turning21.component';
import { ClientSessionsComponent } from './members/registred-clients/client-sessions/client-sessions.component';
import { SearchMembersComponent } from './members/search-members/search-members.component';
import { MemberVerificationComponent } from './members/member-verification/member-verification.component';
import { AddMembersComponent } from './members/add-members/add-members.component';
import { MembersProfileUpdatesComponent } from './members/members-profile-updates/members-profile-updates.component';
import { MemberProfileComponent } from './members/member-profile/member-profile.component';

import { AreaOfInterestAdminComponent } from './settings/area-of-interest-admin/area-of-interest-admin.component';

import { DemoRequestListComponent } from './manage-admins/admin-demo-request/demo-request-list/demo-request-list.component';
import { DemoRequestDetailsComponent } from './manage-admins/admin-demo-request/demo-request-details/demo-request-details.component';

import { NewsEventsComponent } from './news-events/news-events.component';
import { HomePageComponent } from './static-content/home-page/home-page.component';
import { PostNotificationComponent } from './static-content/home-page/post-notification/post-notification.component';
import { EditNotificationComponent } from './static-content/home-page/edit-notification/edit-notification.component';
import { ArchivedPostComponent } from './static-content/home-page/archived-post/archived-post.component';
import { EventsListComponent } from './static-content/events/events-list/events-list.component';
import { CreateEventComponent } from './static-content/events/create-event/create-event.component';
import { EditEventComponent } from './static-content/events/edit-event/edit-event.component';
import { ContentMainComponent } from './static-content/content-main/content-main.component';

import { SurveyListComponent } from './polls-and-survey/survey-list/survey-list.component';
import { PollsAndSurveyComponent } from './polls-and-survey/polls-and-survey.component';
import { EditSurveyComponent } from './polls-and-survey/edit-survey/edit-survey.component';
import { SurveyResultsComponent } from './polls-and-survey/survey-results/survey-results.component';
import { NewPollComponent } from './polls-and-survey/Polls/new-poll/new-poll.component';
import { PollsListComponent } from './polls-and-survey/Polls/polls-list/polls-list.component';
import { EditPollComponent } from './polls-and-survey/Polls/edit-poll/edit-poll.component';
import { PollResultsComponent } from './polls-and-survey/Polls/poll-results/poll-results.component';

import { StatsMainComponent } from './stats/stats-main/stats-main.component';
import { AppStatsComponent } from './stats/app-stats/app-stats.component';
import { DepartmentsStatsComponent } from './stats/departments-stats/departments-stats.component';
import { DemographicsComponent } from './stats/demographics/demographics.component';

import { SettingsComponent } from './settings/settings.component';
import { AppSettingsComponent } from './settings/app-settings/app-settings.component';
import { LandingPageSettingsComponent } from './settings/landing-page-settings/landing-page-settings.component';
import { AdminMenuSettingsComponent } from './settings/admin-menu-settings/admin-menu-settings.component';
import { AppMenuComponent } from './settings/app-menu/app-menu.component';
import { MemberMenuSettingsComponent } from './settings/member-menu-settings/member-menu-settings.component';
import { SectionSettingComponent } from './settings/section-setting/section-setting.component';
import { AddSectionComponent } from './settings/section-setting/add-section/add-section.component';
import { CustomSectionsComponent } from './custom-sections/custom-sections.component';
import { AddCustomSectionComponent } from './custom-sections/add-custom-section/add-custom-section.component';
import { LoginAndSignupSettingsComponent } from './settings/login-and-signup-settings/login-and-signup-settings.component';
import { DashboardSettingComponent } from './settings/dashboard-setting/dashboard-setting.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { ContactSettingsComponent } from './settings/contact-settings/contact-settings.component';
import { SectionListComponent } from './section-list/section-list.component';
import { TestComponent } from './test/test.component';

import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationListComponent } from './applications/application-list/application-list.component';
import { CreateApplicationComponent } from './applications/create-application/create-application.component';
import { EditApplicationComponent } from './applications/edit-application/edit-application.component';
import { PreviewApplicationsComponent } from './applications/preview-applications/preview-applications.component';
import { LrrcnApplicationComponent } from './applications/lrrcn-application/lrrcn-application.component';
import { LrrcnApplicationListComponent } from './applications/lrrcn-application/lrrcn-application-list/lrrcn-application-list.component';
import { IncomingApplicationsComponent } from './incoming-applications/incoming-applications.component';
import { IncomeAssistanceComponent } from './incoming-applications/income-assistance/income-assistance.component';
import { PostSecondayComponent } from './incoming-applications/post-seconday/post-seconday.component';
import { HousingApplicationComponent } from './incoming-applications/housing-application/housing-application.component';
import { ScisApplicationsComponent } from './incoming-applications/scis-applications/scis-applications.component';

import { EditProfileComponent } from './employee/edit-profile/edit-profile.component';
import { EmployeesRegistrationRequestComponent } from './department/employees-registration-request/employees-registration-request.component';
import { InviteEmployeeComponent } from './department/manage-department-members/invite-employee/invite-employee.component';

import { EmployersComponent } from './Employers/employers.component';
import { EmployersListComponent } from './Employers/employers-list/employers-list.component';
import { EmployerDetailComponent } from './Employers/employer-detail/employer-detail.component';
import { SkillsDatabankComponent } from './skills-databank/skills-databank.component';
import { JobPostingsComponent } from './Employers/job-postings/job-postings.component';
import { PostJobComponent } from './Employers/job-postings/post-job/post-job.component';
import { EditJobComponent } from './Employers/job-postings/edit-job/edit-job.component';
import { ArchiveJobComponent } from './Employers/job-postings/archive-job/archive-job.component';
import { TrainingsComponent } from './Employers/trainings/trainings.component';
import { AddTrainingComponent } from './Employers/trainings/add-training/add-training.component';
import { EditTrainingComponent } from './Employers/trainings/edit-training/edit-training.component';
import { ArchiveTrainingComponent } from './Employers/trainings/archive-training/archive-training.component';

import { HousingComponent } from './housing/housing.component';
import { NewPropertyComponent } from './housing/new-property/new-property.component';
import { PropertyDetailComponent } from './housing/property-detail/property-detail.component';
import { UpdatePropertyComponent } from './housing/update-property/update-property.component';
import { RepairRequestsComponent } from './housing/repair-requests/repair-requests.component';
import { RenovationRequestsComponent } from './housing/renovation-requests/renovation-requests.component';

import { DemoVideosComponent } from './demo-videos/demo-videos.component';
import { NewDemoVideoComponent } from './demo-videos/new-demo-video/new-demo-video.component';
import { EditDemoVideoComponent } from './demo-videos/edit-demo-video/edit-demo-video.component';

import { SocialMediaMainComponent } from '../shared/social-media/social-media-main.component';
import { SocialMediaDashBoardComponent } from '../shared/social-media/dashboard/dashboard.component';
import { SocialMediaPostComponent } from '../shared/social-media/post/post.component';
import { SocialMediaAnalyticsComponent } from '../shared/social-media/analytics/analytics.component';
import { SocialMediaSettingsComponent } from '../shared/social-media/settings/settings.component';
import { SocialMediaCreatePostComponent } from '../shared/social-media/post/create-post/create-post.component';
import { LandingEditorPageComponent } from './landing-editor-page/landing-editor-page.component';

import { MessagingComponent } from './messaging/messaging.component';
import { MyInboxComponent } from './messenger/my-inbox/my-inbox.component';
import { MessengerComponent } from './messenger/messenger.component';

import { FileManagerComponent } from './file-sharing/file-manager/file-manager.component';
import { ShareNewFileComponent } from './file-sharing/share-new-file/share-new-file.component';
import { ApprovePaymentsComponent } from './approve-payments/approve-payments.component';
import { LandingPageComponent } from './settings/landing-page/landing-page.component';
import { AllMenuSettingsComponent } from './settings/all-menu-settings/all-menu-settings.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminMainComponent,
    canActivate: [AdminAuthGuard],
    children: [
      {
        path: 'dashboard',
        component: AdminHomeComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'section-list',
        component: SectionListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'landing-editor-page',
        component: LandingEditorPageComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'test',
        component: TestComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'dashboard-sub-menu',
        component: AdminHomeSubMenuComponent,
        canActivate: [AdminAuthGuard],
        children: [

          // ********************** dashboard-sub-menu >  About Us **********************
          {
            path: 'about-us',
            component: AboutUsComponent,
            canActivate: [AdminAuthGuard]
          },

          // ********************** dashboard-sub-menu > Nation Info **********************
          {
            path: 'nation-settings',
            component: NationSettingsComponent,
            canActivate: [AdminAuthGuard]
          },

          // ********************** dashboard-sub-menu > Departments **********************
          {
            path: 'department',
            component: DepartmentComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'manage-department',
            component: ManageDepartmentComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'add-department',
            component: AddEditDepartmentComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'add-member-to-department',
            component: AddMemberToDepartmentComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-department-employee/:id',
            component: EditDepartmentEmployeeComponent,
            canActivate: [AdminAuthGuard]
          },

          // ********************** dashboard-sub-menu > Governmnet **********************
          {
            path: 'governance',
            component: GovernanceComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-governance',
            component: EditGovernanceComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'add-chief',
            component: AddChiefComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-chief',
            component: AddChiefComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'add-councillor',
            component: AddCouncillorComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-councillor',
            component: AddCouncillorComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'manage-councillor-account',
            component: ManageCouncillorAccountComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'manage-chief-account',
            component: ManageChiefAccountComponent,
            canActivate: [AdminAuthGuard]
          },

          // **************** dashboard-sub-menu > Board **************************
          {
            path: 'manage-boards',
            component: BoardsListComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'add-board',
            component: AddBoardComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-board/:id',
            component: AddBoardComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'manage-board-members/:id',
            component: BoardMembersComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'add-board-chair',
            component: AddBoardChairComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'add-board-chair/:id',
            component: AddBoardChairComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'manage-board-member-acc',
            component: ManageBoardMemberAccComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'archived-board-members/:id',
            component: ArchivedBoardMembersComponent,
            canActivate: [AdminAuthGuard]
          },

          // ********************** dashboard-sub-menu > Admins *****************************
          {
            path: 'admins-list',
            component: AdminsListComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'admin-detail/:id',
            component: AdminDetailComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'admin-activity/:id',
            component: AdminActivityComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'activity-detail/:id',
            component: ActivityDetailComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'invite-admin',
            component: InviteAdminComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'manage-other-roles',
            component: ManageOtherRolesComponent,
            canActivate: [AdminAuthGuard]
          },

          //********************** dashboard-sub-menu > Area of Interest **********************
          {
            path: 'areas-of-interest',
            component: AreaOfInterestAdminComponent,
            canActivate: [AdminAuthGuard]
          },

          // ********************** dashboard-sub-menu > polls-survey ********************** 
          {
            path: 'survey-list',
            component: SurveyListComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'polls-surveys',
            component: PollsAndSurveyComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-survey/:id',
            component: EditSurveyComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'survey-result/:id',
            component: SurveyResultsComponent,
            canActivate: [AdminAuthGuard]
          },
          // ********************** dashboard-sub-menu > profile *****************************
          {
            path: 'profile',
            component: ProfileComponent,
            canActivate: [AdminAuthGuard]
          },
          // ********************** dashboard-sub-menu > Statstics *****************************
          {
            path: 'stats',
            component: StatsMainComponent,
            canActivate: [AdminAuthGuard],
            children: [
              {
                path: 'app-statistics',
                component: AppStatsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'dep-statistics',
                component: DepartmentsStatsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'demographics',
                component: DemographicsComponent,
                canActivate: [AdminAuthGuard]
              },
            ]
          },

          // ********************** dashboard-sub-menu > Setting *****************************
          {
            path: 'settings',
            component: SettingsComponent,
            canActivate: [AdminAuthGuard],
            children: [
              {
                path: 'app-settings',
                component: AppSettingsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'landing-page-settings',
                component: LandingPageSettingsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'admin-menu-settings',
                component: AdminMenuSettingsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'app-menu-settings',
                component: AppMenuComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'app-all-menu-settings',
                component: AllMenuSettingsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'member-menu-settings',
                component: MemberMenuSettingsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'login-signup-settings',
                component: LoginAndSignupSettingsComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'section-settings',
                component: SectionSettingComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'add-section',
                component: AddSectionComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'edit-section/:id',
                component: AddSectionComponent,
                canActivate: [AdminAuthGuard]
              },
            ]
          },


          // ********************** dashboard-sub-menu > Housing *****************************
          {
            path: 'housing',
            component: HousingComponent,
            canActivate: [AdminAuthGuard],
          },
          {
            path: 'new-property',
            component: NewPropertyComponent,
            canActivate: [AdminAuthGuard],
          },
          {
            path: 'property-detail/:id',
            component: PropertyDetailComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'update-property/:id',
            component: UpdatePropertyComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'repair-request',
            component: RepairRequestsComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'renovation-request',
            component: RenovationRequestsComponent,
            canActivate: [AdminAuthGuard]
          },
        ]
      },

      //*********************** About Us ************************ */
      {
        path: 'add-about-section',
        component: NewAboutSectionComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-about-section/:id',
        component: UpdateAboutSectionComponent,
        canActivate: [AdminAuthGuard]
      },

      // **********************  Membership **********************
      {
        path: 'members',
        component: MembersComponent,
        canActivate: [AdminAuthGuard],
        // children: [
        //   {
        //     path: 'manage-members',
        //     component: ManageMembersComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'member-detail/:id',
        //     component: MemberDetailComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'registration-request',
        //     component: RegistrationRequestComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'request-detail/:id',
        //     component: RequestDetailComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'registred-clients',
        //     component: RegistredClientsComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'client-detail/:id',
        //     component: ClientDetailComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'upcoming-birthdays',
        //     component: UpcomingBirthdaysComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'congratulate-members',
        //     component: CongratulateMembersComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'members-turning16',
        //     component: MembersTurning16Component,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'members-turning18',
        //     component: MembersTurning18Component,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'members-turning21',
        //     component: MembersTurning21Component,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'member-activity/:id',
        //     component: ClientSessionsComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'search-members',
        //     component: SearchMembersComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'member-verification',
        //     component: MemberVerificationComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'add-members',
        //     component: AddMembersComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'profile-updates',
        //     component: MembersProfileUpdatesComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        //   {
        //     path: 'member-profile/:id',
        //     component: MemberProfileComponent,
        //     canActivate: [AdminAuthGuard]
        //   },
        // ]
      },

      // ********************** application **********************
      {
        path: 'applications',
        component: ApplicationsComponent,
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: 'list',
            component: ApplicationListComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'create',
            component: CreateApplicationComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit/:name',
            component: EditApplicationComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'preview/:name',
            component: PreviewApplicationsComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'lrrcn',
            component: LrrcnApplicationComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'lrrcn-list',
            component: LrrcnApplicationListComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'incoming-apps',
            component: IncomingApplicationsComponent,
            canActivate: [AdminAuthGuard],
            children: [
              {
                path: 'income-assistance',
                component: IncomeAssistanceComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'post-secondary',
                component: PostSecondayComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'housing',
                component: HousingApplicationComponent,
                canActivate: [AdminAuthGuard]
              },
              {
                path: 'scis-application',
                component: ScisApplicationsComponent,
                canActivate: [AdminAuthGuard]
              },
            ]
          },
        ]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AdminAuthGuard]
      },
  
      // ********************** News - Events **************************
      {
        path: 'news-events',
        component: NewsEventsComponent,
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: 'home-page',
            component: HomePageComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'new-card',
            component: PostNotificationComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-card/:id',
            component: EditNotificationComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'archived-post',
            component: ArchivedPostComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'event-list',
            component: EventsListComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'create-event',
            component: CreateEventComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'edit-event/:id',
            component: EditEventComponent,
            canActivate: [AdminAuthGuard]
          },
        ]
      },

      // ********************* Social Media ******************************
      {
        path: 'social-media',
        component: SocialMediaMainComponent,
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: 'dashboard',
            component: SocialMediaDashBoardComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'posts',
            component: SocialMediaPostComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'create-post',
            component: SocialMediaCreatePostComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'analytics',
            component: SocialMediaAnalyticsComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'settings',
            component: SocialMediaSettingsComponent,
            canActivate: [AdminAuthGuard]
          },
          { path: '', redirectTo: 'social-media', pathMatch: 'full' },
        ]
      },

      //*********************** Side-menu ************************ */
      {
        path: 'side-menu',
        component: SideMenuComponent,
        canActivate: [AdminAuthGuard],
      },

      // ********************** Custom Section **********************
      {
        path: 'custom-section/:id',
        component: CustomSectionsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'add-content/:secId',
        component: AddCustomSectionComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-content/:secId/:id',
        component: AddCustomSectionComponent,
        canActivate: [AdminAuthGuard]
      },


      // ********************** Messaging  *****************************
      {
        path: 'messages',
        component: MessagingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'myinbox',
        component: MyInboxComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'messenger',
        component: MessengerComponent,
        canActivate: [AdminAuthGuard]
      },

      // ********************** Othres  *****************************
      {
        path: 'file-manager',
        component: FileManagerComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'share-new-file',
        component: ShareNewFileComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'approve-payments',
        component: ApprovePaymentsComponent,
        canActivate: [AdminAuthGuard]
      },

      // ********************** Section List  **********************

      {
        path: 'section-list',
        component: SectionListComponent,
        canActivate: [AdminAuthGuard]
      },

      // ********************** Test  **********************

      // {
      //   path: 'test',
      //   component: TestComponent,
      //   canActivate: [AdminAuthGuard]
      // },

      // **************** dashboard-sub-menu > Department ********************
      // {
      //   path: 'department-news/:id',
      //   component: ListDepNewsComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'add-department-news/:id',
      //   component: AddDepNewsComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'edit-department-news/:id',
      //   component: EditDepNewsComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'manage-sub-department',
      //   component: ManageSubDepartmentComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'add-subdepartment',
      //   component: AddEditSubdepartmentComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'edit-subdepartment',
      //   component: AddEditSubdepartmentComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'manage-department-members',
      //   component: ManageDepartmentMembersComponent,
      //   canActivate: [AdminAuthGuard]
      // },

      // ********************** dashboard-sub-menu > School **********************
      // {
      //   path: 'school',
      //   component: SchoolComponent,
      //   canActivate: [AdminAuthGuard],
      // },
      // {
      //   path: 'new-school',
      //   component: NewSchoolComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'school-detail/:id',
      //   component: SchoolDetailComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'school-list',
      //   component: SchoolListComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'subject',
      //   component: SubjectComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'courses-list',
      //   component: CoursesComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'add-course',
      //   component: AddCourseComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'edit-course/:id',
      //   component: EditCourseComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'class-room/:id',
      //   component: ClassRoomComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'class-room-detail/:id1/:id2',
      //   component: ClassRoomDetailComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'staff',
      //   component: StaffComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'staff-detail/:id',
      //   component: StaffDetailComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'edit-add-staff',
      //   component: AddEditStaffComponent,
      //   canActivate: [AdminAuthGuard]
      // },


      // ******************** dashboard-sub-menu > Governance **********************
      // {
      //   path: 'governance-main',
      //   component: GovernanceMainComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'add-governance',
      //   component: AddGovernanceComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'election',
      //   component: ElectionComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'new-election',
      //   component: NewElectionComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'edit-election/:id',
      //   component: EditElectionComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'election-result/:id',
      //   component: ElectionResultComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'nomination-candidate-list',
      //   component: NominationCandidateListComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'choose-candidate/:id',
      //   component: ChooseCandidateComponent,
      //   canActivate: [AdminAuthGuard]
      // },


      // ********************* dashboard-sub-menu > Demo Request ********************** 
      // {
      //   path: 'demo-request-list',
      //   component: DemoRequestListComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'demo-request-detail/:id',
      //   component: DemoRequestDetailsComponent,
      //   canActivate: [AdminAuthGuard]
      // },


      // ********************** dashboard-sub-menu > Setting ********************** 
      // {
      //   path: 'dashboard-setting',
      //   component: DashboardSettingComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'social-media',
      //   component: SocialMediaComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'contact-settings',
      //   component: ContactSettingsComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'login-signup-settings',
      //   component: LoginAndSignupSettingsComponent,
      //   canActivate: [AdminAuthGuard]
      // },


      //*********************** Employees ************************ */
      // {
      //   path: 'myprofile',
      //   component: EditProfileComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'employees-registration-request/:id',
      //   component: EmployeesRegistrationRequestComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'invite-department-employee/:id',
      //   component: InviteEmployeeComponent,
      //   canActivate: [AdminAuthGuard]
      // },


      //*********************** Employeers ************************ */
      // {
      //   path: 'employers',
      //   component: EmployersComponent,
      //   canActivate: [AdminAuthGuard],
      //   children: [
      //     {
      //       path: 'employers-list',
      //       component: EmployersListComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'employer-detail/:id',
      //       component: EmployerDetailComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'skills-databank',
      //       component: SkillsDatabankComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'job-list',       //path: 'job-postings',
      //       component: JobPostingsComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'post-job',
      //       component: PostJobComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'edit-job/:id',
      //       component: EditJobComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'archive-job',
      //       component: ArchiveJobComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'trainings',
      //       component: TrainingsComponent,
      //       canActivate: [AdminAuthGuard],
      //     },
      //     {
      //       path: 'add-training',
      //       component: AddTrainingComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'edit-training/:id',
      //       component: EditTrainingComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //     {
      //       path: 'archive-training',
      //       component: ArchiveTrainingComponent,
      //       canActivate: [AdminAuthGuard]
      //     },
      //   ]
      // },

      //*********************** polls/ surveys ************************ */
      // {
      //   path: 'new-poll',
      //   component: NewPollComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'poll-result/:id',
      //   component: PollResultsComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'polls-list',
      //   component: PollsListComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'edit-poll/:id',
      //   component: EditPollComponent,
      //   canActivate: [AdminAuthGuard]
      // },



      // ********************** Demo videos *****************************
      //  {
      //   path: 'demo-videos',
      //   component: DemoVideosComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'new-demo-video',
      //   component: NewDemoVideoComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      // {
      //   path: 'edit-demo-video/:id',
      //   component: EditDemoVideoComponent,
      //   canActivate: [AdminAuthGuard]
      // },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AdminRoutingModule { }

